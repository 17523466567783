import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    children:[
        // {
        //   path: '/home',
        //   name: 'Home',
        //   component: () => import('../views/Home.vue'),
        //   meta:{
        //     path1:'首页',
        //   }
        // },
      {
        path: '/userManager',
        name: 'userManager',
        component: () => import('../views/userManager.vue'),
        meta:{
          path1:'用户管理',
        }
      },
      {
        path: '/shopManager',
        name: 'shopManager',
        component: () => import('../views/shopManager.vue'),
        meta:{
          path1:'商家管理',
        }
      },
      {
        path: '/addressManager',
        name: 'addressManager',
        component: () => import('../views/addressManager.vue'),
        meta:{
          path1:'配送地址管理',
        }
      },
      {
        path: '/orderManager',
        name: 'orderManager',
        component: () => import('../views/orderManager.vue'),
        meta:{
          path1:'订单管理',
        }
      }
    ]
  },
  {
    path: '/login',
    component: login
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
