import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from "vue-router";
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import api from "@/api/api";
import utils from "@/common/utils";


import '@/assets/css/common.css';


Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueRouter)
Vue.prototype.$api = api;
Vue.prototype.$utils = utils;



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
