import api from "@/api/api";
import moment from "moment";

const utils = {};
utils.convertStamp2Time = function (timestamp) {
    return moment(Number(timestamp)).format('YYYY-MM-DD HH:mm:ss')
}

utils.oss = {
    host: 'https://recruit-obe-static.oss-cn-beijing.aliyuncs.com',
    bucket: 'recruit-obe-static'
}

export default utils;
