import axios from "axios";
import Vue from "vue";
import router from "@/router";

const nodeEnv = process.env.NODE_ENV;
let BASE_URL = "https://minipro.teach01.com/takeaway/";
// let BASE_URL = "https://5253l596q5.zicp.fun/takeaway";

const api = axios.create({
    baseURL: BASE_URL,
    withCredentials: false,
    timeout: 50000000
});


// 定义响应拦截器
api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    // 状态错误的情况下，不由axios处理
    return error.response;
})
/**
 * 封装post请求
 * @param url 地址
 * @param params 参数
 * @param warning 是否警告
 * @param headers header
 * @param tokenNeed 是否需要token
 * @returns {Promise<any>}
 */
api.doPost = async function (url, params, warning = true, headers = {},tokenNeed = true) {
    // headers.Authorization = SECRET;
    if (tokenNeed){
        let token = api.getToken();
        if (token){
            headers.Authorization = token;
        }else {
            await router.push('/login')
            return;
        }
    }
    let response = await api.post(url, params, {headers, warning});
    dealResponse(response,warning);
    return response.data;
}
api.doPostForm = async function (url ,params,headers={}){
    let form = new FormData();
    let keys = Object.keys(params);
    for (let key of keys) {
        form.append(key,params[key]);
    }
    console.log(form,'form');
    let response = await api.post(url, form, {headers});
    return response.data;
}

/**
 * 分装get请求
 * @param url 地址
 * @param warning 是否警告
 * @param headers header
 * @param tokenNeed 是否需要token
 * @returns {Promise<any>}
 */
api.doGet = async function (url, warning = true, headers = {},tokenNeed = true) {
    // headers.Authorization = SECRET;
    if (tokenNeed){
        let token = api.getToken();
        if (token){
            headers.Authorization = token;
        }else {
            await router.push('/login')
            return;
        }
    }
    let response = await api.get(url, {headers});
    dealResponse(response,warning);
    return response.data;
}
/**
 * 根据 url参数名获取url参数
 * @param query url
 * @param variable 参数名
 * @returns {string|null} 参数值
 */
api.getQueryVariable = function (query,variable)
{
    let vars = query.split("&");
    for (let i=0;i<vars.length;i++) {
        let pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return null;
}
// 统一处理请求
const dealResponse = (response,warning)=>{
    // 警告处理
    if(warning){
        if (response.data.code == 1){
            Vue.prototype.$message.warning(response.data.message);
            return;
        }else if (response.data.code < 0){
            // token认证处理
            if (response.data.code == -3){
                router.push('/login')
            }
            Vue.prototype.$message.error(response.data.message);
            return;
        }
    }
    // token认证处理
    if (response.data.code == -3){
        router.push('/login')
    }
}
api.getToken = () =>{
    return localStorage.getItem('token');
}
export default api;
