<template>
  <div id="login" class="wx-pingfang wx-flexc">
    <div class="login-content wx-flexr">
      <div class="content-left">
        <img src="../assets/pic/left.png" alt="">
      </div>
      <div class="content-right">
        <div class="welcome">欢迎登陆</div>
        <el-form :model="userForm" :rules="userRules" ref="userForm">
          <el-form-item prop="username">
            <el-input v-model="userForm.username" placeholder="请输入用户名" @keyup.native.enter="submit">
              <i slot="suffix" class="el-icon-user el-input__icon"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="userForm.password" placeholder="请输入密码" :type="showPassword" @keyup.native.enter="submit">
              <i slot="suffix" class="el-icon-view el-input__icon" @click="changePasswordShow"></i>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="submit-bt" @click="submit">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data(){
    return{
      userForm:{
        username:'',
        password:''
      },
      userRules:{
        username:[
          {required:true,message:'请输入用户名',trigger:'blur'}
        ],
        password:[
          {required:true,message:'请输入密码',trigger:'blur'}
        ]
      },
      showPassword:'password'
    }
  },
  methods:{
    changePasswordShow(){
      if (this.showPassword == 'password'){
        this.showPassword = '';
      }else {
        this.showPassword = 'password';
      }
    },
    async submit(){
      await this.$refs.userForm.validate(async (valid)=>{
        if (valid){
          let {data,code} = await this.$api.doPost('/user/admin/login',{
            username:this.userForm.username,
            password:this.userForm.password
          },true,{},false);
          if(code == 0){
            localStorage.setItem('token',data);
            // let {data:dataRet,code:codeRet} = await this.$api.doGet('getUserInfo');
            // if (codeRet == 0){
            //   localStorage.setItem('user',JSON.stringify(dataRet));
              await this.$router.push('/');
            // }
          }
        }else {
          console.log("error submit");
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#login{
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: url("https://obe-study.oss-cn-beijing.aliyuncs.com/static/backgroundx.png") no-repeat 100% 100%;
}
.login-content{
  width: 924px;
  .content-left{
    width: 462px;
    height: 486px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .content-right{
    width: 462px;
    height: 486px;
    background: white;
    padding: 100px 61px 100px 62px;
    box-sizing: border-box;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    .welcome{
      color: #37457E;
      font-size: 30px;
      font-weight: bold;
      padding-bottom: 30px;
    }
    .submit-bt{
      margin-top: 60px;
      background: #3d4c86;
      color: white;
      text-align: center;
      cursor: pointer;
      height: 50px;
      width: 340px;
      line-height: 50px;
      font-size: 18px;
      border-radius: 5px;
    }
  }
}
</style>
